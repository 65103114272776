module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xicoder profile Portfolio","short_name":"Xicoder Portfolio","start_url":"/","background_color":"#f5f5f5","theme_color":"#484349","display":"standalone","icon":"src/images/favicons/lightmode/android-chrome-512x512.png","icons":[{"src":"src/images/favicons/lightmode/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/lightmode/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicons/lightmode/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/lightmode/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e7e5a5c1dae2fe4f114089d39c4060c"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
